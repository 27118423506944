<template>
  <v-card v-if="exhibitor" class="pa-4" :height="$vuetify.breakpoint.mdAndDown ? 640 : 580">
    <ColorCircle :color="exhibitor_type_colors[exhibitor.type]"/>
    <div class="ml-4" style="float:left; height: 60px" >
      {{exhibitor_type_names[exhibitor.type]}}
      <br />
      <span v-if="exhibitor.start_time">
        {{formatTime(exhibitor.start_time)}} - {{formatTime(exhibitor.end_time)}}
      </span>
    </div>
    <br style="clear:both">
    <v-card-title v-if="exhibitor.type == 'speaker' || exhibitor.type == 'organisation'">{{exhibitor.name}}</v-card-title>
    <v-card-title v-else>{{exhibitor.headline}}</v-card-title>
    <v-card-text>
      <div v-html="exhibitor.description_short"/>
      <a :href="'/aussteller/' + exhibitor.id" target="_blank" style="color: darkgray">mehr</a>
    </v-card-text>
    
    <div style="position:absolute; bottom:0; left: 0; width:100%">
      <router-link v-if="exhibitor.preview_image" :to="'/aussteller/' + exhibitor.id" target="_blank">
        <img style="border-radius: 4px; margin: auto; display:block " :src="'https://directus.offenestadt.info/assets/' + exhibitor.preview_image.id + '?height=180&fit=contain'" :title="exhibitor.preview_image.title" height="180px"/>
      </router-link>

      <v-card-text class="pa-1">
        <v-chip-group show-arrows>
          <v-chip v-for="tag in exhibitor.tags" :key="tag.id" class="ma-1 white--text" :color="tag.tags_id.color" small>{{tag.tags_id.name}}</v-chip>
        </v-chip-group>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
  import ColorCircle from '@/components/ColorCircle.vue'

  export default {
    name: 'ExhibitorCard',
    props: {exhibitor_id: {type: Number}},
    components: {
      ColorCircle
    },
    data: () => ({
      exhibitor: null,
      exhibitor_type_colors:{ 'speaker': '#B62234',
                            'panel': '#E1C721',
                            'organisation': '#59863C',
                            'project': '#1F446C',
                            'deep_dive': '#59863C',
                            'keynote': '#B62234',
                            'greeting': '#E1C721'},
      exhibitor_type_names:{  'speaker': 'Speaker:innen',
                              'panel': 'Panel',
                              'organisation': 'Organisation',
                              'project': 'Projekt',
                              'deep_dive': 'Deep Dive',
                              'keynote': 'Keynote',
                              'greeting': 'Begrüßung'}


    }),
    created() {
      this.$client.items('exhibitors').readOne(this.exhibitor_id, { fields: ['*.*','tags.*.*']})
                  .then(data => {
                      this.exhibitor = data
                  })
                  .catch(error => console.error(error));
    },
    computed: {
      previewImage: function() {

        if (!this.exhibitor.media || this.exhibitor.media.length == 0) return ''

        let content = ''

        return content
      },
    },
    methods: {
      formatTime: function(time) {
        let datetime = new Date('2021-01-01T' + time)
        return datetime.getHours() + ':' + String(datetime.getMinutes()).padStart(2, '0');
      }
    }
  }
</script>