<template>
  <div >
    <v-container fluid class="pt-0 px-0">
      <v-img src="../assets/Teilnehmer_innen.jpg" style="margin:auto" max-height="400px">
        <v-container class="white--text fill-height">
          <v-row align="center" no-gutters>
            <v-col cols="12">
              <ColorCircle color="white" />
            </v-col>
            <v-col cols="12" md="6">
              <h1>Teilnehmer:innen</h1>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-container>
    <v-container>
      <v-row>
        <v-col v-if="$vuetify.breakpoint.smAndDown">
          <v-btn small @click="filterByType()" outlined class="ma-2 white--text">Alle</v-btn>
          <v-btn small @click="filterByType('speaker')" outlined class="ma-2 white--text">Speaker</v-btn>
          <v-btn small @click="filterByType('keynote')" outlined class="ma-2 white--text">Keynote</v-btn>
          <v-btn small @click="filterByType('panel')" outlined class="ma-2 white--text">Panel</v-btn>
          <v-btn small @click="filterByType('projects')" outlined class="ma-2 white--text">Projekte</v-btn>
          <v-btn small @click="filterByType('organisation')" outlined class="ma-2 white--text">Organisation</v-btn>
          <v-btn small @click="filterByType('deep_dive')" outlined class="ma-2 white--text">Deep Dive</v-btn>
        </v-col>
        <v-col v-else>
          <v-btn-toggle tile group>
            <v-btn @click="filterByType()" outlined class="white--text">Alle</v-btn>
            <v-btn @click="filterByType('speaker')" outlined class="white--text">Speaker</v-btn>
            <v-btn @click="filterByType('keynote')" outlined class="white--text">Keynote</v-btn>
            <v-btn @click="filterByType('panel')" outlined class="white--text">Panel</v-btn>
            <v-btn @click="filterByType('projects')" outlined class="white--text">Projekte</v-btn>
            <v-btn @click="filterByType('organisation')" outlined class="white--text">Organisation</v-btn>
            <v-btn @click="filterByType('deep_dive')" outlined class="white--text">Deep Dive</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-for="(exhibitor, index) in exhibitors" :key="index">
          <!--<v-lazy min-height="500" :options="{threshold: 0}" class="fill-height">-->
            <ExhibitorCard :exhibitor_id="exhibitor.id" />
          <!--</v-lazy>-->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ExhibitorCard from '@/components/ExhibitorCard.vue'
import ColorCircle from '@/components/ColorCircle.vue'

export default {
  name: 'ExhibitorOverview',
  components: {
    ExhibitorCard,
    ColorCircle
  },
  data: () => ({
    exhibitors: [],
  }),
  async created() {
    let temp = await this.$client.items("exhibitors").readMany({ fields: ['*.*'], sort: 'headline'})
    this.exhibitors = temp.data
  },
  methods: {
    filterByType: async function(type) {

      let filter = null
      
      if (type) filter = {'type': {'_eq':type}}

      let temp = await this.$client.items("exhibitors").readMany({ fields: ['*.*'], 'filter': filter, sort: 'headline'})
      
      this.exhibitors = []
      
      // need, otherwise freaky behaviour
      await this.$nextTick()

      this.exhibitors = Object.assign({}, this.exhibitors, temp.data)
    }
  }
}
</script>
